<template>
  <div class="login-vue" :style="bg">
    <div class="container">
      <p class="title">WELCOME</p>
      <div v-if="!showQrCode" class="input-c">
        <Input prefix="ios-contact" v-model="account" placeholder="用户名" clearable />
        <p class="error">{{ accountError }}</p>
      </div>
      <div v-if="!showQrCode" class="input-c">
        <Input type="password" v-model="pwd" prefix="md-lock" placeholder="密码" clearable @keyup.enter.native="submit" />
        <p class="error">{{ pwdError }}</p>
      </div>
      <div v-else class="qr-code-container">
        <img :src="qrCode" class="qr-code" alt="二维码" @click="scanQRCode" title="点击刷新二维码" />
      </div>
      <div class="buttons">
        <Button v-if="!showQrCode && !isShowLoading" class="submit" type="primary" @click="submit">
          登录
        </Button>
        <Button v-else class="qr-login submit" type="dashed" ghost @click="showLoginForm">
          密码登录
        </Button>
        <Button v-if="!showQrCode" :loading="isShowLoading" class="qr-login submit" type="dashed" ghost
          @click="scanQRCode">
          扫码登录
        </Button>
        <Button class="qq-login submit" id="qqLoginBtn" type="dashed" @click="openQQLogin">
          QQ登录
        </Button>
      </div>
      <p class="account">
        <span @click="register">注册账号</span> |
        <span @click="forgetPwd">忘记密码</span>
      </p>
    </div>

    <div class="icp-number">
      <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer" style="color: inherit">
        浙ICP备2022033133号
      </a>
    </div>
  </div>
</template>

<script>
import QC from "../utils/qqApi.js";
import { Message } from "view-design";
import JSEncrypt from "jsencrypt";
import { login, getQrCode, userInfo } from "@/api/user.js";
import { connectWebSocket } from "../utils/WebSocketUtil";

export default {
  name: "login",
  data() {
    return {
      userScan: {},
      account: "",
      pwd: "",
      accountError: "",
      pwdError: "",
      isShowLoading: false,
      bg: {},
      showQrCode: false,
      qrCode: "",
    };
  },
  created() {
    this.bg.backgroundImage =
      "url(" +
      require("../assets/imgs/bg0" + new Date().getDay() + ".jpg") +
      ")";
  },
  watch: {
    $route: {
      handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  methods: {
    handleMessage(message) {
      if (
        message.scanId == this.userScan.scanId &&
        message.status == "CONFIRMED"
      ) {
        Message.success({
          content: "登录成功!",
        });
        localStorage.setItem("token", message.token);
        this.closeWebSocket();
        userInfo({}).then((result) => {
          localStorage.setItem("currentUser", JSON.stringify(result.retVal));
          this.$router.push({ path: this.redirect || "/" });
        });
      }
    },
    register() { },
    forgetPwd() { },
    submit() {
      let pubKey =
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCkPhdjvFe62qpyqA4zHRNJZwln+zgOxKAZ5c2H8TmqAK9XPemyU0UR0sZnefy/8VNaF2qiTepKynZVNgc2glJwDwhW+lUEeqYeT+DIcAdi+yx9MNPhkeMQ+8JJ92kyHio48m+On+NrOFjY1ZMZtQRNeVmeniCK2N30ijjFvT+IAQIDAQAB";
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(pubKey);
      login({
        username: this.account,
        password: encrypt.encrypt(this.pwd),
        isRemember: false,
      }).then((res) => {
        Message.success({
          content: res.retVal,
        });
        userInfo({}).then((result) => {
          localStorage.setItem("currentUser", JSON.stringify(result.retVal));
          this.$router.push({ path: this.redirect || "/" });
        });
      });
    },
    scanQRCode() {
      getQrCode().then((res) => {
        const userScanDTO = res.retVal;
        this.qrCode = "data:image/png;base64," + userScanDTO.qrcode;
        this.showQrCode = true;
        this.userScan = userScanDTO.userScanLogin;
        connectWebSocket.call(
          this,
          "wss://www.bjca.xyz/diary/subWebsocket/" +
          userScanDTO.userScanLogin.scanId,
          this.handleMessage
        );
      });
      this.account = "";
      this.pwd = "";
    },
    closeWebSocket() {
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        this.ws.close();
      }
    },
    showLoginForm() {
      this.showQrCode = false;
      this.closeWebSocket();
    },
    openQQLogin() {
        QC.Login.showPopup({
          appId: '102029917',
          redirectURI: 'https://www.bjca.xyz/#/qq-login-callback'
        })
    },
  },
};
</script>

<style>
.login-vue {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  backdrop-filter: blur(10px);
  /* 轻微模糊 */
  background: rgba(0, 0, 0, 0.5);
  /* 调整透明度 */
}

.login-vue .container {
  background: rgba(255, 255, 255, 0.9);
  /* 更简约的背景色 */
  width: 400px;
  text-align: center;
  border-radius: 12px;
  /* 微调圆角 */
  padding: 35px 45px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  /* 降低阴影强度 */
}

.login-vue .title {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 1.5px;
  margin-bottom: 20px;
  color: #2d8cf0;
}

.login-vue .ivu-input {
  background-color: rgba(255, 255, 255, 0.85);
  color: #333;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px 40px;
  /* 调整内边距 */
  position: relative;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.login-vue .submit,
.login-vue .qq-login,
.login-vue .qr-login {
  width: 250px;
  /* 调整按钮宽度 */
  margin-top: 15px;
  border-radius: 25px;
  padding: 12px 0;
  /* 上下内边距 */
  font-weight: 500;
  font-size: 16px;
  /* 更小的字体 */
  color: #fff;
  text-align: center;
  line-height: 1;
  /* 保证文字垂直居中 */
  transition: background 0.3s ease, transform 0.3s ease;
}

.login-vue .submit {
  background: linear-gradient(45deg, #2d8cf0, #1e7bee);
}

.login-vue .submit:hover {
  background: linear-gradient(45deg, #1e7bee, #2d8cf0);
  transform: translateY(-2px);
}

.login-vue .qq-login {
  background-color: #10b2f5;
}

.login-vue .qq-login:hover {
  background-color: #0da6e2;
  transform: translateY(-2px);
}

.login-vue .qr-login {
  background: none;
  border: 2px solid #2d8cf0;
  color: #2d8cf0;
}

.login-vue .qr-login:hover {
  background-color: rgba(45, 140, 240, 0.1);
  transform: translateY(-2px);
}

.icp-number {
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 11px;
  background: rgba(0, 0, 0, 0.5);
  padding: 4px;
  border-radius: 5px;
}

.login-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
</style>
